/* General container styles */
.operations-dashboard {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f3f4f6;
    position: relative;
}

/* Home Button Icon */
.home-button {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 1.5em;
    cursor: pointer;
    transition: color 0.3s ease;
}

.home-button:hover {
    color: #004494;
}

/* Pending Uploads Badge - positioned at the top right of the screen */
.badge {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: #ff4d4f;
    color: #fff;
    padding: 5px 10px;
    border-radius: 50px;
    font-size: 0.8rem;
    font-weight: bold;
    transition: transform 0.2s ease, background-color 0.2s ease;
}

.badge:hover {
    background-color: #e43a3c;
    transform: scale(1.05);
}

/* Heading - Scales with screen size */
.operations-dashboard h1 {
    font-size: clamp(1.5em, 4vw, 2em);
    color: #0056b3;
    margin-bottom: 20px;
    text-align: center;
    font-weight: bold;
    padding-top: 10px;
}

/* Welcome message */
.operations-dashboard p {
    font-size: clamp(1em, 2.5vw, 1.1em);
    color: #555;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
}

/* Dashboard content layout */
.dashboard-content {
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr;
}

/* Dashboard section cards */
.dashboard-section {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: relative;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    cursor: pointer;
}

.dashboard-section:hover {
    background-color: #f8f9fa;
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
    border: 1px solid #e3e3e3;
}

.dashboard-section h3 {
    font-size: clamp(1.2em, 3vw, 1.5em);
    color: #333;
    margin-bottom: 8px;
    font-weight: bold;
}

.dashboard-section p {
    font-size: clamp(0.9em, 2.5vw, 1em);
    color: #666;
    margin: 0;
}

/* Info Icon in each card - positioned in the top right of each card */
.dashboard-section .info-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.2em;
    color: #007bff;
    cursor: pointer;
    transition: color 0.3s;
}

.dashboard-section .info-icon:hover {
    color: #0056b3;
}

/* Modal styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    width: 90vw;
    max-width: 400px;
    padding: 25px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;
}

/* Close Button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 1.5em;
    color: #888;
    cursor: pointer;
    transition: color 0.3s;
}

.close-button:hover {
    color: #333;
}

/* Unique style for Form Creation section */
.dashboard-section.form-creation-section {
    background-color: #e0f7fa; /* Light cyan */
    /*border-left: 5px solid #00796b; !* Teal border *!*/
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-section.form-creation-section:hover {
    background-color: #b2ebf2; /* Slightly darker cyan */
    box-shadow: 0 8px 16px rgba(0, 121, 107, 0.2);
    transform: scale(1.02);
}

/* Unique style for Offline Data Entry section */
.dashboard-section.offline-entry-section {
    background-color: #fff3e0; /* Light orange */
    /*border-left: 5px solid #f57c00; !* Orange border *!*/
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.dashboard-section.offline-entry-section:hover {
    background-color: #ffe0b2; /* Slightly darker orange */
    box-shadow: 0 8px 16px rgba(245, 124, 0, 0.2);
    transform: scale(1.02);
}

/* Mobile adjustments */
@media (max-width: 600px) {
    .operations-dashboard {
        padding: 15px;
    }

    .home-button {
        top: 10px;
        left: 10px;
        font-size: 1.2em;
    }

    .dashboard-section {
        padding: 15px;
    }

    .modal-content {
        padding: 20px;
    }

    .dashboard-section h3 {
        font-size: 1.2em;
    }

    .dashboard-section p {
        font-size: 1em;
    }
}

/* Adjust modal for mobile screens */
@media (max-width: 600px) {
    .modal-content {
        max-width: 90vw;
        max-height: 75vh;
        overflow-y: auto;
        padding: 15px;
    }
}
