/* Modal Content Styling */
.login-register-modal-content {
    width: 90%;
    max-width: 400px;
    padding: 1.5rem;
    margin: auto;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Subtle shadow for a lifted effect */
    max-height: 80vh; /* Limit the modal height */
    overflow-y: auto; /* Enable vertical scrolling */
}

/* Overlay for Modal Background */
.login-register-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Form Layout */
.login-register-form {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

/* Input, Select, and Button Styling */
.login-register-form input,
.login-register-form select,
.login-register-form button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease;
}

.login-register-form input:focus,
.login-register-form select:focus {
    outline: none;
    border-color: #006e8c;
    box-shadow: 0 0 4px rgba(0, 110, 140, 0.2);
}

/* Button Styling */
button {
    background-color: #006e8c;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #00556d;
}

/* Error Message Styling */
.error-message {
    color: #d9534f;
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-align: center;
}

/* Responsive Design Adjustments */
@media (max-width: 480px) {
    .login-register-modal-content {
        padding: 1rem;
    }

    .login-register-form input,
    .login-register-form select,
    .login-register-form button {
        padding: 0.5rem;
        font-size: 0.9rem;
    }

    button {
        font-size: 0.9rem;
        padding: 0.6rem;
    }
}
