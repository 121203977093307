/* Disclaimer.css */
.disclaimer-container {
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    font-family: Arial, sans-serif;
    color: #333;
}

.disclaimer-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #005f73;
}

.disclaimer-text {
    font-size: 14px;
    line-height: 1.5;
}
