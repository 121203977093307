/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

/* Modal Content */
.modal-content {
    width: 90vw;
    max-width: 420px;
    max-height: 80vh;
    padding: 20px;
    padding-bottom: 40px;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    animation: fadeIn 0.3s ease-in-out;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(20px); }
    to { opacity: 1; transform: translateY(0); }
}

.modal-content h2 {
    font-size: 22px;
    color: #333;
    text-align: center;
    font-weight: bold;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .modal-content {
        max-height: 75vh;
    }

    .modal-content h2 {
        font-size: 20px;
    }

    .modal-content label,
    .category-dropdown label {
        font-size: 14px;
    }

    .modal-content button,
    .category-actions button {
        font-size: 14px;
    }
}

/* Category List */
.category-list {
    list-style: none;
    padding: 0;
    margin: 0 0 15px;
}

.category-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: #f3f4f6;
    border-radius: 12px;
    margin-bottom: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
}

.category-item:hover {
    transform: scale(1.02);
}

.category-item strong {
    font-size: 16px;
    color: #333;
    flex-grow: 1;
}

.category-item p {
    font-size: 14px;
    color: #666;
    margin: 0;
    max-width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Category Action Buttons */
.category-actions {
    display: flex;
    gap: 8px;
}

.category-actions button {
    padding: 6px 10px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    background-color: #cccccc;
    color: #333;
    transition: background-color 0.3s;
}

.category-actions button:hover {
    background-color: #b3b3b3;
}

/* Form Fields */
.modal-content label {
    font-size: 15px;
    color: #444;
    font-weight: 600;
}

.modal-content input,
.modal-content textarea {
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 10px;
    font-size: 15px;
    color: #333;
    background-color: #f0f2f5;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.modal-content input:focus,
.modal-content textarea:focus {
    background-color: #e8ecf1;
    outline: none;
    box-shadow: 0px 0px 4px rgba(0, 123, 255, 0.3);
}

.modal-content textarea {
    resize: vertical;
    min-height: 80px;
}

/* Button Group */
.button-group {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 10px;
}

.modal-content button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    gap: 8px;
    border: none;
    border-radius: 12px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
    background-color: #cccccc;
    color: #333;
    transition: background-color 0.3s, transform 0.2s;
}

.modal-content button:hover {
    background-color: #b3b3b3;
}

.modal-content button:active {
    transform: scale(0.98);
}

/* Close Button */
.close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #888;
    cursor: pointer;
    transition: color 0.3s;
}

.close-button:hover {
    color: #333;
}

/* Dropdown */
.category-dropdown {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.category-dropdown select {
    padding: 12px;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    color: #333;
    background-color: #f0f2f5;
    transition: background-color 0.2s;
}

.category-dropdown select:focus {
    background-color: #e8ecf1;
    outline: none;
}

/* Message Popup - Centered and Above All Content */
.message-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 12px 20px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    z-index: 1101; /* Ensures it stays above all other content */
    transition: opacity 0.5s ease-in-out;
    opacity: 0.95;
    text-align: center;
    width: 80%;
    max-width: 300px;
}

.message-popup.success {
    background-color: #28a745; /* Green for success */
}

.message-popup.error {
    background-color: #dc3545; /* Red for error */
}

/* Confirmation Modal */
.confirm-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
}

.confirm-modal-content {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 320px;
    width: 90%;
}

.confirm-modal-content p {
    font-size: 16px;
    color: #333;
    margin-bottom: 20px;
    font-weight: 500;
}

/* Confirmation Buttons */
.confirm-buttons {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.button-confirm,
.button-cancel {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 12px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: #cccccc;
    color: #333;
    transition: background-color 0.3s;
}

.button-confirm:hover,
.button-cancel:hover {
    background-color: #b3b3b3;
}

/* Status Indicator Styles */
.status-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 8px;
    vertical-align: middle;
}

/* Online - Green */
.status-indicator.online {
    background-color: #28a745; /* Green */
    box-shadow: 0 0 6px rgba(40, 167, 69, 0.5);
}

/* Offline - Red */
.status-indicator.offline {
    background-color: #dc3545; /* Red */
    box-shadow: 0 0 6px rgba(220, 53, 69, 0.5);
}

/* Badge for Online/Offline Status */
.status-badge {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    padding: 5px 10px;
    border-radius: 12px;
    background-color: #f8f9fa;
    color: #333;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
    width: fit-content;
}

.status-badge.online {
    border: 1px solid #28a745; /* Green Border */
}

.status-badge.offline {
    border: 1px solid #dc3545; /* Red Border */
}

/* Status Text Alignment */
.status-text {
    margin-left: 8px;
    font-size: 14px;
    font-weight: bold;
}
