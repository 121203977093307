/* AdminPanel.css */

.admin-panel {
    padding: 1rem;
    font-family: Arial, sans-serif;
}

.admin-table {
    width: 100%;
    border-collapse: collapse;
}

.admin-table th, .admin-table td {
    border: 1px solid #ddd;
    padding: 0.5rem;
    text-align: left;
}

.organizations-cell {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.org-list {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 5px;
}

.organization-tag {
    background-color: #f0f0f0;
    padding: 0.3rem 0.5rem;
    border-radius: 4px;
    font-size: 0.9rem;
}

.edit-orgs-button, .delete-user-button {
    padding: 0.3rem 0.6rem;
    font-size: 0.9rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    color: #fff;
}

.edit-orgs-button {
    background-color: #007bff;
    margin-top: 5px;
}

.delete-user-button {
    background-color: #dc3545;
}

.edit-orgs-button:hover {
    background-color: #0056b3;
}

.delete-user-button:hover {
    background-color: #c82333;
}

/* Modal Styles */
.org-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1rem;
    max-width: 90%;
    width: 400px;
    border-radius: 8px;
    outline: none;
}

.org-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

.org-multi-select-modal {
    width: 100%;
    height: 150px;
    margin: 1rem 0;
    padding: 0.5rem;
    font-size: 1rem;
}

.modal-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.modal-submit-button, .modal-cancel-button {
    padding: 0.5rem 1rem;
    font-size: 0.9rem;
    cursor: pointer;
    border: none;
    border-radius: 4px;
}

.modal-submit-button {
    background-color: #28a745;
    color: #fff;
}

.modal-cancel-button {
    background-color: #6c757d;
    color: #fff;
}

.modal-submit-button:hover {
    background-color: #218838;
}

.modal-cancel-button:hover {
    background-color: #5a6268;
}

/* Responsive Design */
@media (max-width: 600px) {
    .admin-table th, .admin-table td {
        font-size: 0.9rem;
        padding: 0.3rem;
    }

    .organization-tag {
        font-size: 0.8rem;
    }

    .edit-orgs-button, .delete-user-button {
        font-size: 0.8rem;
        padding: 0.3rem 0.5rem;
    }

    .org-modal {
        width: 90%;
        padding: 1rem;
    }
}
