/* OfflineEntryDashboard.css */

/* Modal Overlay */
.offline-entry-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

/* Modal Content */
.offline-entry-modal-content {
    width: 100%;
    max-width: 500px;
    max-height: 90vh;
    background-color: #ffffff;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.3s ease-in-out;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    padding: 20px; /* Added padding */
}

@keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
}

/* Close Button */
.offline-entry-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 30px;
    color: #888;
    cursor: pointer;
    transition: color 0.3s;
    z-index: 1;
}

.offline-entry-close-button:hover {
    color: #333;
}

/* Titles and Subtitles */
.offline-entry-title {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 20px 0;
    text-align: center;
    color: #333;
}

.offline-entry-subtitle {
    font-size: 18px;
    font-weight: bold;
    margin: 20px 0 10px 0;
    color: #555;
}

/* Sections */
.offline-entry-section {
    padding: 0;
}

.offline-entry-form-section {
    padding: 0;
}

.offline-entry-form-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #444;
}

/* Select and Inputs */
.offline-entry-select,
.offline-entry-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    margin-bottom: 15px;
    background-color: #f9f9f9;
    box-sizing: border-box;
}

.offline-entry-select:focus,
.offline-entry-input:focus {
    outline: none;
    border-color: #3f51b5;
    background-color: #fff;
}

/* Instance List */
.offline-entry-instance-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.offline-entry-instance-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.offline-entry-instance-button {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: #e0e0e0;
    border-radius: 8px;
    font-size: 14px;
    text-align: left;
    color: #333;
    cursor: pointer;
    transition: background-color 0.2s;
}

.offline-entry-instance-button:hover {
    background-color: #d5d5d5;
}

.offline-entry-delete-button {
    margin-left: 10px;
    padding: 10px;
    border: none;
    background-color: #f44336;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.2s;
}

.offline-entry-delete-button:hover {
    background-color: #e53935;
}

/* Field Items */
.offline-entry-field-item {
    margin-bottom: 15px;
}

.offline-entry-label {
    display: block;
    font-size: 16px;
    margin-bottom: 5px;
    color: #555;
}

.offline-entry-error .offline-entry-label {
    color: #f44336;
}

.offline-entry-warning .offline-entry-label {
    color: #ff9800;
}

.offline-entry-input {
    display: block;
}

.offline-entry-error-input {
    border-color: #f44336;
    background-color: #ffebee;
}

.offline-entry-warning-input {
    border-color: #ff9800;
    background-color: #fff3e0;
}

.offline-entry-error-text {
    color: #f44336;
    font-size: 12px;
    margin-top: 5px;
}

/* Button Group */
.offline-entry-button-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
}

.offline-entry-save-button,
.offline-entry-submit-button {
    flex: 1;
    padding: 15px;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.offline-entry-save-button {
    background-color: #4caf50;
    color: #fff;
}

.offline-entry-save-button:hover {
    background-color: #43a047;
}

.offline-entry-save-button:active {
    background-color: #388e3c;
}

.offline-entry-submit-button {
    background-color: #2196f3;
    color: #fff;
}

.offline-entry-submit-button:hover {
    background-color: #1e88e5;
}

.offline-entry-submit-button:active {
    background-color: #1976d2;
}

/* Image Container */
.offline-entry-image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%; /* Ensure the container takes full width */
}

.offline-entry-image-preview {
    max-width: 100%;
    max-height: 300px; /* Set a maximum height */
    height: auto;
    margin-top: 10px;
    object-fit: contain; /* Ensure the image scales properly */
    border: 1px solid #ddd;
    border-radius: 8px;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
    .offline-entry-modal-content {
        border-radius: 0;
        max-height: 95vh;
        padding: 15px; /* Adjusted padding */
    }

    .offline-entry-title {
        font-size: 20px;
        margin-top: 30px;
    }

    .offline-entry-subtitle {
        font-size: 16px;
        margin: 15px 0 8px 0;
    }

    .offline-entry-form-title {
        font-size: 18px;
    }

    .offline-entry-label {
        font-size: 14px;
    }

    .offline-entry-select,
    .offline-entry-input {
        font-size: 14px;
        padding: 10px;
    }

    .offline-entry-instance-button {
        font-size: 13px;
        padding: 8px;
    }

    .offline-entry-delete-button {
        font-size: 13px;
        padding: 8px;
    }

    .offline-entry-save-button,
    .offline-entry-submit-button {
        font-size: 16px;
        padding: 12px;
    }

    .offline-entry-button-group {
        flex-direction: column;
    }

    .offline-entry-save-button {
        margin-bottom: 10px;
    }

    /* Adjust image preview */
    .offline-entry-image-preview {
        max-height: 200px;
    }
}

/* Scrollbar Styling */
.offline-entry-modal-content::-webkit-scrollbar {
    width: 6px;
}

.offline-entry-modal-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.offline-entry-modal-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.offline-entry-modal-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Adjustments for Smaller Devices */
@media (max-width: 400px) {
    .offline-entry-title {
        font-size: 18px;
        margin-top: 25px;
    }

    .offline-entry-subtitle {
        font-size: 14px;
        margin: 10px 0 5px 0;
    }

    .offline-entry-form-title {
        font-size: 16px;
    }

    .offline-entry-label {
        font-size: 13px;
    }

    .offline-entry-select,
    .offline-entry-input {
        font-size: 13px;
        padding: 8px;
    }

    .offline-entry-instance-button,
    .offline-entry-delete-button {
        font-size: 12px;
        padding: 6px;
    }

    .offline-entry-save-button,
    .offline-entry-submit-button {
        font-size: 14px;
        padding: 10px;
    }

    /* Further adjust image preview */
    .offline-entry-image-preview {
        max-height: 150px;
    }
}
