/* Modal overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content */
.modal-content {
    background: #ffffff;
    border-radius: 8px;
    padding: 20px;
    width: 90%;
    max-width: 800px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 90%;
    position: relative;
}

/* Close button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #888;
    transition: color 0.3s;
}

.close-button:hover {
    color: #000;
}

/* Dropdown */
.category-select-container {
    margin-bottom: 20px;
}

.category-dropdown {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #f9f9f9;
}

/* Table */
.data-table-container {
    margin-top: 20px;
    overflow-x: auto; /* Allow horizontal scrolling for smaller screens */
}

.data-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 0.9rem;
}

.data-table th,
.data-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.data-table th {
    background: #f4f4f4;
    font-weight: bold;
}

.data-table tr:nth-child(even) {
    background: #f9f9f9;
}

.data-table tr:hover {
    background: #f1f1f1;
}

/* Error message */
.error-message {
    color: red;
    font-size: 0.9rem;
    margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 480px) {
    .modal-content {
        padding: 15px;
        width: 95%;
    }

    .data-table th,
    .data-table td {
        font-size: 0.8rem;
    }
}
