/* Navbar.css */

/* Reset default styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Top Navbar */
.top-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    border-bottom: 1px solid #e0e0e0;
    z-index: 1000;
}

.navbar-left {
    display: flex;
    align-items: center;
}

.menu-button {
    background: none;
    border: none;
    font-size: 24px;
    margin-right: 15px;
    color: #333333;
    cursor: pointer;
}

.navbar-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333333;
}

.navbar-right {
    display: flex;
    align-items: center;
}

.navbar-button {
    background: none;
    border: none;
    font-size: 24px;
    color: #333333;
    cursor: pointer;
}

.navbar-button:hover {
    color: #007AFF;
}

/* Side Menu */
.side-menu {
    position: fixed;
    top: 60px; /* Height of top navbar */
    left: -250px; /* Initially off-screen */
    width: 250px;
    height: calc(100% - 60px);
    background-color: #f9f9f9;
    transition: left 0.3s ease;
    z-index: 999;
    overflow-y: auto;
}

.side-menu.open {
    left: 0; /* Slides in when open */
}

.menu-content {
    padding: 20px;
}

.user-info {
    margin-bottom: 20px;
}

.navbar-username {
    font-size: 1rem;
    color: #333333;
}

.navbar-dropdown {
    margin-bottom: 20px;
}

.dropdown-label {
    display: block;
    margin-bottom: 5px;
    font-size: 0.9rem;
    color: #555555;
}

.navbar-select {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    font-size: 1rem;
    border: 1px solid #cccccc;
    background-color: #ffffff;
    color: #333333;
}

.navbar-options {
    display: flex;
    flex-direction: column;
}

.navbar-link {
    display: flex;
    align-items: center;
    padding: 10px 0;
    text-decoration: none;
    color: #333333;
    font-size: 1rem;
    border-bottom: 1px solid #e0e0e0;
}

.navbar-link:last-child {
    border-bottom: none;
}

.navbar-link:hover,
.navbar-link.active {
    color: #007AFF;
}

.navbar-link-icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
    object-fit: contain;
}

/* Overlay for side menu */
.menu-overlay {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 500;
    display: none; /* Hidden by default */
}

.menu-overlay.open {
    display: block; /* Show overlay when menu is open */
}

/* Adjust content to not overlap with navbar */
body {
    padding-top: 60px;
}

/* Responsive adjustments */
@media (min-width: 600px) {
    /*.side-menu {*/
    /*    width: 300px;*/
    /*}*/
}
