/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Content */
.modal-content {
    width: 90vw;
    max-width: 420px;
    max-height: 85vh;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
}

/* Heading */
.modal-content h2 {
    font-size: 22px;
    color: #333;
    text-align: center;
    font-weight: bold;
    margin: 0;
}

/* Tab Buttons */
/* Tab Buttons */
.tab-buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 15px 0;
}

.tab-button {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    background-color: #f0f0f0;
    color: #333;
    transition: background-color 0.3s;
    text-align: center;
}

.tab-button.active {
    background-color: #4a90e2;
    color: white;
    border-color: #4a90e2;
}

.tab-button:hover {
    background-color: #e0e0e0;
}

/* Field Item */
.field-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 8px;
    margin-bottom: 8px;
}

.field-actions {
    display: flex;
    gap: 8px;
}

/* Message Popup */
.message-popup {
    padding: 10px 15px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    transition: opacity 0.5s ease-in-out;
    margin-bottom: 15px;
}

/* Success and Error Messages */
.message-popup.success {
    background-color: #28a745;
}

.message-popup.error {
    background-color: #dc3545;
}

/* Category Dropdown */
.category-dropdown {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.category-dropdown select {
    width: 100%;
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 16px;
    background-color: #f9f9f9;
    color: #333;
}

/* Field Form */
.field-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.field-form h3 {
    font-size: 18px;
    color: #333;
    margin: 0;
    text-align: left;
}

.field-form input,
.field-form select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 6px;
    font-size: 15px;
    color: #333;
    background-color: #f0f2f5;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.field-form input:focus,
.field-form select:focus {
    background-color: #e8ecf1;
    box-shadow: 0px 0px 4px rgba(0, 123, 255, 0.3);
}

.field-form label {
    font-size: 15px;
    color: #555;
    font-weight: 600;
}

.field-form .checkbox-label {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* Save and Cancel Buttons */
.button-save, .button-cancel {
    padding: 12px;
    font-size: 15px;
    font-weight: bold;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button-save {
    background-color: #34c759;
    color: #fff;
}

.button-cancel {
    background-color: #888;
    color: #fff;
}

.button-save:hover {
    background-color: #28a745;
}

.button-cancel:hover {
    background-color: #555;
}

/* Close Button */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    color: #888;
    cursor: pointer;
    transition: color 0.3s;
}

.close-button:hover {
    color: #333;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .modal-content {
        width: 95vw;
        max-width: none;
        max-height: 75vh;
        padding: 15px;
    }

    .modal-content h2 {
        font-size: 20px;
    }

    .field-form h3, .fields-list h3 {
        font-size: 16px;
    }

    .field-form input, .field-form select, .category-dropdown select {
        font-size: 14px;
    }

    .button-save, .button-cancel, .button-edit, .button-delete {
        font-size: 14px;
        padding: 10px;
    }

    .message-popup {
        font-size: 13px;
        padding: 8px 12px;
    }
}
