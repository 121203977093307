/* HomePage.css */

.home-page {
    padding: 20px;
    text-align: center;
}

.home-title {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #2C3E50; /* Navy Blue */
}

.home-subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #7F8C8D; /* Gray */
}

.nodes-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.node-card {
    background-color: #FFFFFF;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: 200px;
    text-decoration: none;
    color: #2C3E50;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
}

.node-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.node-icon {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 10px;
}

.node-info {
    text-align: center;
}

.node-name {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.node-description {
    font-size: 0.9rem;
    color: #7F8C8D;
}

.node-card.active-node {
    border: 2px solid #007AFF; /* Highlight color */
    background-color: #f0f8ff; /* Light background for selected node */
    box-shadow: 0 4px 12px rgba(0, 122, 255, 0.2); /* Subtle shadow */
}
/* HomePage.css */

/* Existing styles */
.home-page {
    text-align: center;
}

.nodes-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;
}

.node-card {
    width: 180px;
    padding: 16px;
    text-align: center;
    background-color: #f4f4f4;
    border-radius: 8px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.node-card:hover {
    transform: scale(1.05);
}

/* Active node style */
.active-node {
    border: 2px solid #007AFF;
    background-color: #e0f2ff; /* Light blue background */
}
/* Grayed-out style for unavailable nodes */
.grayed-out {
    opacity: 0.5;
    pointer-events: none; /* Prevent interaction */
}


@media (max-width: 600px) {
    .nodes-grid {
        flex-direction: column;
        align-items: center;
    }

    .node-card {
        width: 100%;
        max-width: 300px;
    }
}
