/* FormCreationDashboard.css */

/* Modal Overlay */
.form-dashboard-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden;
}

/* Modal Content */
.form-dashboard-modal-content {
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    background-color: #ffffff;
    border-radius: 16px;
    position: relative;
    display: flex;
    flex-direction: column;
    animation: fadeIn 0.3s ease-in-out;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    box-sizing: border-box;
    padding: 20px;
}

@keyframes fadeIn {
    from { opacity: 0; transform: scale(0.95); }
    to { opacity: 1; transform: scale(1); }
}

/* Close Button */
.form-dashboard-close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 30px;
    color: #888;
    cursor: pointer;
    transition: color 0.3s;
}

.form-dashboard-close-button:hover {
    color: #333;
}

/* Titles and Subtitles */
.form-dashboard-title {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.form-dashboard-subtitle {
    font-size: 20px;
    font-weight: bold;
    color: #555;
    margin-bottom: 10px;
}

/* Sections */
.form-dashboard-section {
    margin-bottom: 20px;
}

/* Form Elements */
.form-dashboard-form {
    display: flex;
    flex-direction: column;
}

.form-dashboard-label {
    font-size: 16px;
    color: #444;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.form-dashboard-input,
.form-dashboard-select {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 16px;
    background-color: #f9f9f9;
    margin-top: 5px;
}

.form-dashboard-input:focus,
.form-dashboard-select:focus {
    outline: none;
    border-color: #3f51b5;
    background-color: #fff;
}

/* Fields Container */
.form-dashboard-fields-container {
    margin-top: 20px;
}

.form-dashboard-field-group {
    margin-bottom: 15px;
}

.form-dashboard-field-group-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.form-dashboard-select-all-label {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
}

.form-dashboard-checkbox {
    margin-right: 5px;
}

.form-dashboard-field-item {
    margin-left: 20px;
    margin-top: 5px;
}

.form-dashboard-field-label {
    font-size: 16px;
    color: #555;
    display: flex;
    align-items: center;
}

.form-dashboard-field-label .form-dashboard-checkbox {
    margin-right: 8px;
}

/* Buttons */
.form-dashboard-button-group {
    display: flex;
    margin-top: 20px;
}

.form-dashboard-save-button,
.form-dashboard-cancel-button {
    flex: 1;
    padding: 12px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.form-dashboard-save-button {
    background-color: #4caf50;
    color: #fff;
    margin-right: 10px;
}

.form-dashboard-save-button:hover {
    background-color: #43a047;
}

.form-dashboard-cancel-button {
    background-color: #f44336;
    color: #fff;
}

.form-dashboard-cancel-button:hover {
    background-color: #e53935;
}

/* Existing Forms List */
.form-dashboard-form-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.form-dashboard-form-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #e0e0e0;
    border-radius: 8px;
    margin-bottom: 10px;
}

.form-dashboard-form-name {
    font-size: 16px;
    font-weight: bold;
    color: #333;
}

.form-dashboard-form-actions {
    display: flex;
    align-items: center;
}

.form-dashboard-view-button,
.form-dashboard-edit-button,
.form-dashboard-delete-button {
    padding: 8px 12px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    margin-left: 5px;
    transition: background-color 0.3s;
}

.form-dashboard-view-button {
    background-color: #2196f3;
    color: #fff;
}

.form-dashboard-view-button:hover {
    background-color: #1e88e5;
}

.form-dashboard-edit-button {
    background-color: #ffc107;
    color: #fff;
}

.form-dashboard-edit-button:hover {
    background-color: #ffb300;
}

.form-dashboard-delete-button {
    background-color: #f44336;
    color: #fff;
}

.form-dashboard-delete-button:hover {
    background-color: #e53935;
}

/* View Modal */
.form-dashboard-view-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.form-dashboard-view-content {
    background-color: #fff;
    border-radius: 16px;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
    box-sizing: border-box;
}

.form-dashboard-view-content h4 {
    font-size: 22px;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
}

.form-dashboard-view-content p {
    font-size: 16px;
    color: #555;
    margin-bottom: 10px;
}

.form-dashboard-view-content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin: 0;
}

.form-dashboard-view-content li {
    font-size: 16px;
    color: #555;
    margin-bottom: 5px;
}

.form-dashboard-close-view-button {
    margin-top: 20px;
    padding: 12px;
    width: 100%;
    border: none;
    border-radius: 8px;
    background-color: #2196f3;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.form-dashboard-close-view-button:hover {
    background-color: #1e88e5;
}

/* Responsive Adjustments */
@media (max-width: 600px) {
    .form-dashboard-modal-content {
        border-radius: 0;
        max-height: 95vh;
        width: 100%;
        padding: 15px;
    }

    .form-dashboard-title {
        font-size: 20px;
        margin-top: 30px;
    }

    .form-dashboard-subtitle {
        font-size: 18px;
    }

    .form-dashboard-label {
        font-size: 14px;
    }

    .form-dashboard-input,
    .form-dashboard-select {
        font-size: 14px;
        padding: 10px;
    }

    .form-dashboard-field-group-title {
        font-size: 16px;
    }

    .form-dashboard-field-label {
        font-size: 14px;
    }

    .form-dashboard-save-button,
    .form-dashboard-cancel-button {
        font-size: 14px;
        padding: 10px;
    }

    .form-dashboard-form-name {
        font-size: 14px;
    }

    .form-dashboard-view-button,
    .form-dashboard-edit-button,
    .form-dashboard-delete-button {
        font-size: 12px;
        padding: 6px 8px;
    }

    .form-dashboard-view-content h4 {
        font-size: 20px;
    }

    .form-dashboard-view-content p,
    .form-dashboard-view-content li {
        font-size: 14px;
    }

    .form-dashboard-close-view-button {
        font-size: 14px;
        padding: 10px;
    }
}

/* Scrollbar Styling */
.form-dashboard-modal-content::-webkit-scrollbar,
.form-dashboard-view-content::-webkit-scrollbar {
    width: 6px;
}

.form-dashboard-modal-content::-webkit-scrollbar-track,
.form-dashboard-view-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.form-dashboard-modal-content::-webkit-scrollbar-thumb,
.form-dashboard-view-content::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
}

.form-dashboard-modal-content::-webkit-scrollbar-thumb:hover,
.form-dashboard-view-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}
